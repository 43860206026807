<template>
    <chart-of-account-wrapper>
        <template v-slot:child>
            <manage-account-header />
            <div class="w-90 center mt4">
                <div class="flex justify-between items-center manageHeader secPad">
                    <div class="b w-50">Account</div>
                    <div class="b w-50">Description</div>
                    <div class="b">
                        <!-- Action -->
                    </div>
                </div>
                <section class="secPad">
                    <div class="flex justify-between items-center secFirst">
                        <div class="pl3">Non Current Liabilities</div>
                        <div></div>
                        <div>
                            <!-- <button class="flex items-center" style="gap: 10px">
                                <span><img :src="require('@/assets/images/chartCircle.svg')" /></span><span>Add account</span>
                            </button> -->
                        </div>
                    </div>
                    <div class="flex justify-between items-center secPad text-muted">
                        <div class="w-50 b">Bank Loans</div>
                        <div class="w-50 tl">Long term loans collected from banks</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                </section>
                <section class="secPad">
                    <div class="flex justify-between items-center secFirst">
                        <div class="pl3">Current Liabilities</div>
                        <div></div>
                        <div>
                            <!-- <button class="flex items-center" style="gap: 10px">
                                <span><img :src="require('@/assets/images/chartCircle.svg')" /></span><span>Add account</span>
                            </button> -->
                        </div>
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ name: 'TradePayables' }"> Trade Payable </router-link>
                        </div>
                        <div class="w-50 tl">Amounts outstanding on purchases from suppliers</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ name: 'VatPayables' }"> VAT Payable </router-link>
                        </div>
                        <div class="w-50 tl">VAT balance payable</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad text-muted">
                        <div class="w-50 b">Accrued Expenses</div>
                        <div class="w-50 tl">Amounts owing on expenses that have been consumed</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad text-muted">
                        <div class="w-50 b">Income Tax Payable</div>
                        <div class="w-50 tl">Company income tax payable</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ name: 'LiabilitiesBorrowing' }"> Borrowings </router-link>
                        </div>
                        <div class="w-50 tl">Loans taken by the Business</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                </section>
            </div>
        </template>
    </chart-of-account-wrapper>
</template>
<script>
import ChartOfAccountWrapper from '../widgets/ChartOfAccountWrapper.vue'
import ManageAccountHeader from '../widgets/ManageAccountHeader.vue'
export default {
    name: 'ManageLiability',
    components: { ManageAccountHeader, ChartOfAccountWrapper },
    setup() {
        return {}
    },
}
</script>
<style scoped>
.secPad div {
    align-items: flex-start !important;
}
</style>
